import {useState, useEffect} from 'react'
import { getBookData, publishContent } from '../firebase'
import RoundTimer from '../components/RoundTimer';
import ProgressRing from '../components/ProgressRing';

// todo: Main.js に設定されている maxRoundNum と連携させる

export default function Book({game, bookId, pageNum, uid, onSubmit, room}) {

  // const totalPageNum = pageNum
  const [book, setBook] = useState('')
  const [myContent, setMyContent] = useState('')
  const [isContentPosted, setIsContentPosted] = useState(false)
  function refresh(bookObj) {
    setMyContent('')
    setIsContentPosted(false)
    setBook(bookObj)
  }
  
  useEffect(() => {
    if(!bookId) {
      return
    }
    let isMounted = true
    ;(async function() {
      const book = await getBookData(bookId)
      // unmount されていた場合はコンポーネントをアップデートしない
      if(isMounted) {
        refresh(book)
      }
    })()
    return () => isMounted = false
  }, [bookId])

  const maxContentLength = 100
  const handleChange = (e) => {
    setMyContent(e.target.value.slice(0,maxContentLength))
  }
  
  const [textAnimate, setTextAnimate] = useState(false)
  async function updateContent() {
    try {
      const name = room.players[uid].name
      const newContentsArr = await publishContent(bookId, uid, name, myContent, room.playerNum);
      // setContents( newContentsArr )
      // setMyContent('')
      setIsContentPosted(true)
      setTextAnimate(true)
      onSubmit()

      // todo: playerが1人のとき、onSubmit を待たずに更新しているので、複数人でのプレイと挙動が異なる可能性あり。要確認。
      if(room.playerNum === 1) {
        let newBook = {
          ...book,
          contents: newContentsArr
        }
        refresh(newBook)
      }
    } catch(err) {
      console.error(err);
      throw new Error('投稿をアップロードできませんでした')
    }
  }
  
  const contents        = book.contents || []
  const lastPageNum     = contents.length
  const currentPageNum  = contents.length + 1
  const lastContent     = contents.length ? contents[contents.length - 1] : null
  const buttonText      = isContentPosted ? '投稿を修正する': '送信';
  const progress        = myContent.length / maxContentLength * 100
  const remainCharacter = maxContentLength - myContent.length

  return (
    <div className="book">
      <p className="book__heading"><span className="book__heading-content">『{book.title}』</span></p>
      <div className="book-contents">
        { lastContent &&
          <div className="previous-content">
            <div>
              <p className="contents__last-page"><span className="contents__last-page-num">{lastPageNum}</span> ページ目</p>
              <p className="contents__last">{lastContent.content}</p>
              {/* <div className="contents__last-author">by: {lastContent.name}</div> */}
            </div>
          </div>
        }
        <div className="my-content">
          <p className="book__current-page"><span className="book__current-page-num">{currentPageNum}</span> ページ目</p>
          { game?.config?.timer && game.currentRoundStartedAt_date && <RoundTimer config={game.config} roundStartedAt={game.currentRoundStartedAt_date} />}
          <form>
            <textarea className={'book__input' + (isContentPosted ? ' book__input--posted': '')} name="" id="" rows="10" value={myContent} onChange={handleChange} />
            {/* <p className={'book_character-limit' + (myContent.length === maxContentLength ? ' book_character-limit--caution' : '') }>{myContent.length} /{maxContentLength}文字</p> */}
            <ProgressRing radius="14" stroke="2" progress={progress} num={remainCharacter} />
            <div className="book__submit-area">
              { isContentPosted && <span className={'book__posted-text' + (textAnimate ? ' animate' : '')} onAnimationEnd={()=>{setTextAnimate(false)}}><span className="book__posted-icon">✓</span>投稿されました！</span> }
              <p className="c-btn-wrapper"><input className="c-btn" type="button" value={buttonText} onClick={updateContent}/></p>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
