import { useState,useEffect,useMemo } from 'react'

export default function RoundTimer({config, roundStartedAt, onTimerEnd}) {
  const initialRemainTime = useMemo(() => {
    const roundStartedAt_seconds = Math.floor(roundStartedAt.getTime() / 1000)
    return roundStartedAt_seconds + config.timer * 60 - Math.floor((new Date()).getTime() / 1000)
  }, [config.timer, roundStartedAt])

  const [remainTime_seconds, setRemainTime_seconds] = useState(initialRemainTime)

  useEffect(() => {
    let time = initialRemainTime
    const tick = setInterval(() => {
      if(time <= 0) {
        clearInterval(tick)
        setRemainTime_seconds(0)
      } else {
        time -= 1
        setRemainTime_seconds(time)
      }
    }, 1000)
    return () => {clearInterval(tick)}
  }, [initialRemainTime])

  return (
      <p className="roundTimer">残り <span className={'roundTimer__text' + (remainTime_seconds <= 30 ? ' roundTimer__text--color': '')}>{('00' + Math.floor(remainTime_seconds / 60).toString()).slice(-2)}:{('00' + (remainTime_seconds % 60).toString()).slice(-2)}</span></p>
  )
}
