import { useContext, createContext, useReducer } from "react"

const LoadingContext = createContext()

export function LoadingProvider({children}) {
  const loadingReducer = (prevState, action) => {
    switch (action.type) {
      case 'start': 
        return {
          ...prevState,
          isLoading: true,
          text: action.text || '',
        }
      case 'end': 
        return {
          ...prevState,
          isLoading: false,
        }
      default:
        throw new Error('something is wrong with &dq;loading&dq;')
    }
  }
  const [loading, dispatchLoading] = useReducer(loadingReducer, { isLoading: false, text: '' })

  return (
    <LoadingContext.Provider value={{loading, dispatchLoading}}>
      {children}
    </LoadingContext.Provider>
  )
}

export function useLoading() {
  return useContext(LoadingContext)
}