import { useContext, createContext, useState } from "react"

const PageContext = createContext()

export function PageProvider({children}) {
  const [page, setPage] = useState('initial')

  return (
    <PageContext.Provider value={{page, setPage}}>
      {children}
    </PageContext.Provider>
  )
}

export function usePage() {
  return useContext(PageContext)
}