import logoSvg from '../images/logo.svg';
import { usePage } from '../context/page'

function Header({isLink}) {
  const {setPage}    = usePage()
  const toInitial   = () => {setPage('initial')};

  const clickControl = function() {
    const confirmText = 'じゃれ本トップページに戻ります'
    if(window.confirm(confirmText)) {
      toInitial()
    }
  }
  return (
    <header className="header">
      {
        isLink ?
          <h1 className="header__logo"><button onClick={clickControl}><img src={logoSvg} width="227" height="108" alt="じゃれ本 ONLINE Beta" /></button></h1>
        : <h1 className="header__logo"><img src={logoSvg} width="227" height="108" alt="じゃれ本 ONLINE Beta" /></h1>
      }
    </header>
  )
}

export default Header