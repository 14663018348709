import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';

var firebaseConfig = {
  apiKey: "AIzaSyARkpPFSjTXrooWWD_QGUS5G60xfacMBEY",
  authDomain: "jarebon-online.firebaseapp.com",
  projectId: "jarebon-online",
  storageBucket: "jarebon-online.appspot.com",
  messagingSenderId: "1066449477679",
  appId: "1:1066449477679:web:221d966c87545e4c43e6da",
  measurementId: "G-958P7TC50H"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
export const db = firebase.firestore();