import firebase, {db} from './firebaseConfig.js';
const ref = db.collection('rooms');


// ------------------------------
// |  ROOM
// ------------------------------

export async function submitNewRoom(user, roomName, config) {
  const roomRef = ref.doc();
  const gameRef = roomRef.collection('games').doc();

  // 最初のゲームを作成
  const initialGameData = {
    order: [user.id],
    documents: [],
    currentRound: 0,
    currentRoundStartedAt_timeStamp: null,
    entitled_book_num: 0,
    player_is_ready: {[user.id]: false},
    config,
  }
  gameRef.set(initialGameData)

  // 上で作成したゲームのID情報を含んだルームを作成
  const initialRoomData = {
    'id': roomRef.id,
    name: roomName,
    owner: user.id,
    players: {
      [user.id]: {
        name: user.name,
      }
    },
    status: 'open',
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    games: [gameRef.id]
  }
  await roomRef.set(initialRoomData)
  return initialRoomData;
};

export async function getRoomData(roomId) {
  const roomObj = await ref.doc(roomId).get()
  if(!roomObj.exists) {
    throw new Error('ルームを取得できませんでした。\nIDを確認してください。')
  }
  return roomObj.data()
};

export function joinRoom(roomId, user) {
  // promiseをreturn
  return db.runTransaction(async (transaction)=>{
    const roomObj = await transaction.get(ref.doc(roomId));
    if (!roomObj.exists || roomObj.data().status !== 'open') {
      throw new Error('ルームに参加できませんでした。\nROOM IDを確認してください。');
    }
    const games = roomObj.data().games
    const gameId = games[games.length - 1]
    const gameRef = ref.doc(roomId).collection('games').doc(gameId)
    const player_is_ready = (await transaction.get(gameRef)).data()['player_is_ready']
    transaction.set(ref.doc(roomId), {
        players: {
          [user.id]: {
            name: user.name
          }
        }
    }, {merge: true})
    transaction.set(gameRef, {
      order: firebase.firestore.FieldValue.arrayUnion(user.id),
      player_is_ready: {...player_is_ready, [user.id]: false}
    }, {merge: true})
  })
};

export function waitForPlay(roomId, setRoomData) {
  return new Promise((resolve) => {
    const waiting = ref.doc(roomId).onSnapshot((doc) => {
      const data = doc.data()
      setRoomData(data)
      if(data.status !== 'open') {
        // room ステータスが open から変更されたら Promise.resolve を返す
        unsubscribe()
        return resolve(data.status)
      }
    });
    const unsubscribe = () => {
      console.log('ルームステータスが変化しました');
      // onSnapshot からの返り値を実行すると監視が解除される
      waiting();
    }
  })
};

// ------------------------------
// |  GAME
// ------------------------------

export async function submitNewGame(room, gameId, game) {
  // todo: 本当にgameId, game オブジェクトの両方が必要なのか確認する

    // 1.create document
    const roomId = room.id
    const gameRef = ref.doc(roomId).collection('games').doc(gameId);

    const batch = db.batch();
    const order = game.order;
    const documents = [];
    for (let i = 0, l = order.length; i<l;i++) {
      const bookRef = db.collection('books').doc();

      batch.set(bookRef, {
        id: bookRef.id,
        ownerId: order[i],
        ownerName: room.players[order[i]].name,
        title: room.players[order[i]].name + ' さんの本',
        contents: []
      })
      documents.push(bookRef.id)
    }
    
    batch.set(gameRef, {
      documents
    }, {merge: true})
    await batch.commit()
      .catch((err)=>{
        console.log(err);
        throw new Error('ブックを作成できませんでした');
      });
    // 2.change room status
    await ref.doc(roomId)
      .set({
        status: 'playing'
      },{merge: true})
      .catch((err)=>{
        throw new Error('ルームステータスを変更できませんでした');
      });
    return gameRef.id;
};

export async function getGameData(roomId, gameId) {
  const gameObj = await ref.doc(roomId).collection('games').doc(gameId).get()
  const gameData = gameObj.data()
  gameData.currentRoundStartedAt_date = gameData.currentRoundStartedAt_timeStamp?.toDate()
  return gameData
}

export function watchGameData(roomId, gameId, callBackFunc) {
  const unsubscribe = ref.doc(roomId).collection('games').doc(gameId).onSnapshot((doc) => {
    const gameData = doc.data()
    gameData.currentRoundStartedAt_date = gameData.currentRoundStartedAt_timeStamp?.toDate()
    callBackFunc(gameData, unsubscribe)
  });
  return unsubscribe
}

export async function updatePlayerReady(roomId, gameId, uid, action) {
  const gameRef = ref.doc(roomId).collection('games').doc(gameId)
  const player_is_ready = (await gameRef.get()).data().player_is_ready
  if(player_is_ready[uid] !== true) {
    player_is_ready[uid] = true
  }
  const isReadyArr = Object.values(player_is_ready)
  if (isReadyArr.indexOf(false) > -1) {
    await gameRef.set({ player_is_ready }, {merge: true})
  } else {
    if(action?.type === 'setTitle') {
        await gameRef.set({
          player_is_ready: Object.fromEntries(Object.keys(player_is_ready).map((id) => {return [id, false]})),
          currentRoundStartedAt_timeStamp: firebase.firestore.FieldValue.serverTimestamp()
        }, {merge: true})
      } else {
        // 全員が投稿した段階で
        // game ドキュメントの player_is_ready を全て false に変更し、
        // currentRound を1つ増やす
        gameRef.set({
          player_is_ready: Object.fromEntries(Object.keys(player_is_ready).map((id) => {return [id, false]})),
          currentRound: firebase.firestore.FieldValue.increment(1),
          currentRoundStartedAt_timeStamp: firebase.firestore.FieldValue.serverTimestamp()
        }, {merge: true})
      }
    }
}


// ------------------------------
// |  BOOK
// ------------------------------

export async function getMyBookId(roomId, gameId, userId) {
  const gameObj = await ref.doc(roomId).collection('games').doc(gameId).get()
  const gameData = gameObj.data()
  const targetIndex = gameData.order.indexOf(userId)
  return gameData.documents[targetIndex]
}
export function calcTargetBookId(gameData, userId) {
  const offset = 1 // offset = 0 の場合は自分の本からスタート。1の場合は1つ次の本からスタートする。
  const targetIndex = ( gameData.order.indexOf(userId) + gameData.currentRound + offset ) % gameData.order.length
  return gameData.documents[targetIndex]
}


export async function getAllBooks(bookIdArr) {
  const sliceByNumber = (array, number) => {
    // 配列を一定の長さで分割する
    // （例）[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]  →  [[1, 2, 3], [4, 5, 6], [7, 8, 9], [10]]
    const length = Math.ceil(array.length / number)
    return new Array(length).fill().map((_, i) =>
      array.slice(i * number, (i + 1) * number)
    )
  }

  // firestore のクエリ（where in）が一度に10件までなので、
  // bookIdArrを長さ10以下の配列に分割し、それぞれgetしている
  const slicedBookIdArr = sliceByNumber(bookIdArr, 10)
  const gameCollectionRef = db.collection('books')
  const allBooks = {}

  await Promise.all(slicedBookIdArr.map(async (idArray) => {
    await gameCollectionRef.where(firebase.firestore.FieldPath.documentId(), 'in', idArray)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          allBooks[doc.id] = doc.data()
        })
      })
  }))

  return allBooks
}

export async function getBookData(bookId) {
  const bookRef = db.collection('books').doc(bookId)
  const bookObj = await bookRef.get()
  if(!bookObj.exists) {
    throw new Error('ブックが存在しません。\nIDを確認してください。')
  }
  return bookObj.data()
}

export function submitTitle(roomId, gameId, bookId, title) {
  return db.runTransaction(async (transaction)=>{
    const bookRef = db.collection('books').doc(bookId)
    transaction.set(bookRef, {title}, {merge: true})

    transaction.set(ref.doc(roomId).collection('games').doc(gameId), {
      entitled_book_num: firebase.firestore.FieldValue.increment(1)
    }, {merge: true})
  })
}

export async function publishContent(bookId, userId, name, content, playerNum) {
  const bookRef        = db.collection('books').doc(bookId)
  const bookObj        = await bookRef.get()
  const newContentsArr = bookObj.data().contents.slice()
  const lastAuthorId   = newContentsArr.length > 0 ? newContentsArr.slice(-1)[0].userId : null
  const newContent = {
    name,
    userId,
    content
  }
  if( playerNum > 1 && lastAuthorId === userId ) {
    newContentsArr.splice( -1, 1, newContent )
  } else {
    newContentsArr.push( newContent )
  }
  await bookRef.update({
    contents: newContentsArr
  })
  return newContentsArr
}

// ------------------------------
// |  WORD
// ------------------------------

export async function getWords() {
  const version = 'v0.1'
  let result = {
    'dataA': [],
    'dataB': []
  }
  const info = (await db.collection('words').doc(version).get()).data()

  await Promise.all(
    Object.keys(result).map(async (data) => {
      const n = Math.floor(Math.random() * info[data].length)
      const targetId = info[data][n]
      result[data] = (await db.collection('words').doc(version).collection(data).doc(targetId).get()).data()['data']
      return
    })
  )
  return result
}
