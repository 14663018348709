import {useState} from 'react'
import {getRoomData, joinRoom} from '../firebase'
import { usePage } from '../context/page';

export default function JoinRoom({uid, setRoomData}) {

  const [nameInput, setNameInput] = useState('')
  const [roomIdInput, setRoomIdInput] = useState('')
  function handleChange(e) {
    switch(e.target.name) {
      case 'roomId':
        setRoomIdInput(e.target.value)
        break
      case 'name':
        setNameInput(e.target.value)
        break
      default:
    }
  }

  const {setPage} = usePage()
  async function submitRoomId(e) {
    e.preventDefault();
    if(!roomIdInput || !nameInput) {
      alert('ルームIDと名前を入力してください。')
      return;
    }
    if(nameInput.length > 10) {
      alert('プレイヤー名は最大で10文字までです');
      return;
    }

    const user = {
      id: uid,
      name: nameInput
    }
    try {
      let roomData = await getRoomData(roomIdInput)
      if(!roomData.players[user.id]) {
        await joinRoom(roomData.id, user)
        roomData = await getRoomData(roomIdInput)
      }
      setRoomData(roomData)
      setPage('waiting')
    } catch(err) {
      if(err.message) {
        alert(err.message)
      }
      console.error(err)
    }
  }

  return (
    <div className="join-room">
      <p className="join-room__text">ROOM ID と PLAYER NAME を入力のうえ参加してください。</p>
      <form id="form2" className="join-room__form">
        <div className="join-room__input-wrapper">
          <input className="join-room__input" name="roomId" placeholder="ROOM ID" type="text" value={roomIdInput} onChange={handleChange} />
          <input className="join-room__input" name="name" placeholder="PLAYER NAME" type="text" value={nameInput} onChange={handleChange} />
        </div>
        <div className="c-btn-wrapper">
          <input className="c-btn" type="button" value="部屋に参加" onClick={submitRoomId}/>
        </div>
      </form>
      <div className="c-text-container">
        <h3 className='c-text-container__title c-text-container__title--caution'>&#x26A0; ご注意</h3>
        <p className='c-text-container__paragraph'>ブラウザの「更新ボタン」「前のページに戻るボタン」を押すと、部屋から退出してしまいます。<br />その際、再入場はできませんのでご注意ください。</p>
      </div>
      <p className="join-room__back"><button onClick={() => {setPage('initial')}}>← トップページへ戻る</button></p>
    </div>
  )
}
