import {useState} from 'react'
import { usePage } from '../context/page'
import{submitNewRoom} from '../firebase'
import iconPen from '../images/icon-pen.svg'
export default function CreateRoom({uid, setRoomData}) {

  const {setPage} = usePage()

  const [nameInput, setNameInput] = useState('')
  const [roomNameInput, setRoomNameInput] = useState('')
  const [configTimer, setTimer] = useState('2')
  const [configPageNum, setPageNum] = useState('8')
  function handleChange(e) {
    switch(e.target.name) {
      case 'room-name':
        setRoomNameInput(e.target.value)
        break
      case 'user-name':
        setNameInput(e.target.value)
        break
      case 'timer':
        setTimer(e.target.value)
        break
      case 'pageNum':
        setPageNum(e.target.value)
        break
      default:
    }
  }

  const user = {
    id: uid,
    name: nameInput
  }
  async function createRoom(e) {
    e.preventDefault();
    if(!nameInput || !roomNameInput) {
      alert('ルームタイトルと使用する名前を入力してください');
      return;
    }
    if(nameInput.length > 10 && roomNameInput.length > 10) {
      alert('ルームタイトルとプレイヤー名は最大で10文字までです');
      return;
    }
    if(nameInput.length > 10) {
      alert('プレイヤー名は最大で10文字までです');
      return;
    }
    if(roomNameInput.length > 10) {
      alert('ルームタイトルは最大で10文字までです');
      return;
    }
    try  {
      const config ={
        timer: configTimer < 999 ? configTimer : null,
        pageNum: configPageNum
      }
      const createdRoomData = await submitNewRoom(user, roomNameInput, config)
      setRoomData(createdRoomData)
      alert('ルームID: ' + createdRoomData.id)
      setPage('waiting')
    } catch(err) {
      console.error(err);
    }
    return;
  }

  return (
    <div className="create-room">
      <div className="create-room__icon"><img src={iconPen} alt="" /></div>
      <h2 className="create-room__title">部屋を作成する</h2>
      <form id="form1" className="create-room__form">
        <div className="create-room__input-wrapper">
          <input className="create-room__name" name="room-name" id="roomName" placeholder="ROOM TITLE" type="text" value={roomNameInput} onChange={handleChange} />
          <input className="create-room__name" name="user-name" id="userName" placeholder="PLAYER NAME" type="text" value={nameInput} onChange={handleChange} />
        </div>
        <div className="create-room__setting">{/*上級者設定を追加*/}<br />
          <label htmlFor="timer">制限時間 </label>
          <select className="create-room__select" name="timer" id="timer" value={configTimer} onChange={handleChange}>
            <option value="1">1分</option>
            <option value="2">2分</option>
            <option value="3">3分</option>
            <option value="5">5分</option>
            <option value="999">無制限</option>
          </select>
          <br />
          <label htmlFor="pageNum">一冊のページ </label>
          <select className="create-room__select" name="pageNum" id="pageNum" value={configPageNum} onChange={handleChange}>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
        </div>
        <div className="c-btn-wrapper">
          <input className="c-btn" type="button" value="部屋を作成する" onClick={createRoom}/>
        </div>
      </form>
      <div className="c-text-container">
        <h3 className='c-text-container__title c-text-container__title--caution'>&#x26A0; ご注意</h3>
        <p className='c-text-container__paragraph'>ブラウザの「更新ボタン」「前のページに戻るボタン」を押すと、部屋から退出してしまいます。<br />その際、再入場はできませんのでご注意ください。</p>
      </div>
      <p className="create-room__back"><button onClick={() => {setPage('initial')}}>← トップページへ戻る</button></p>
    </div>
  )
}
