import { usePage } from '../context/page'


export default function Initial() {
  const {setPage}    = usePage()
  const toCreateRoom = () => {setPage('createRoom')};
  const toJoinRoom   = () => {setPage('joinRoom')};
  return (
      <div className="initial">
        <div className="initial-main">
          <button className="initial-main__btn initial-main__btn--create" onClick={toCreateRoom}>部屋を作成する</button>
          <button className="initial-main__btn initial-main__btn--join" onClick={toJoinRoom}>部屋に参加する</button>
        </div>
        <div className="initial-sub">
        <div className="c-text-container">
          <h3 className='c-text-container__title'>遊び方</h3>
          <p className='c-text-container__paragraph'>①まずは題名を決めよう！<br />参加者全員が自分の本の題名を決めます。言葉を組み合わせて、不思議な題名をつくってみましょう！</p>
          <p className='c-text-container__paragraph'>②出だしを書いたあとは、回ってきたページにどんどん続きを書いていこう！「題名」と「前の人の書いたもの」だけを頼りに、物語をリレーをしていきましょう。次の人にきれいなパスを出すもよし、むちゃぶりのキラーパスを出すもよし。楽しみながら書いてください！</p>
        </div>
          <div className="initial-sub__btn-container">
            <a className="initial-sub__btn initial-sub__btn--about" href="https://jarebon.com/#ABOUT" target="_blank">じゃれ本とは？</a>{/* eslint-disable-line react/jsx-no-target-blank */}
            <a className="initial-sub__btn initial-sub__btn--howto" href="https://jarebon.com/howto-online/" target="_blank">詳しいあそびかた</a>{/* eslint-disable-line react/jsx-no-target-blank */}
          </div>
        </div>
      </ div>
  )
}
