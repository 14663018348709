import { useState, useReducer, useEffect } from 'react'
import { getMyBookId, getWords, submitTitle, updatePlayerReady, watchGameData } from '../firebase'
import { usePage } from '../context/page'

export default function SetTitle({room, uid}) {
  const roomId = room.id
  const {playerNum, games} = room

  const [gameId, setGameId] = useState('')
  const [bookId, setBookId] = useState('')
  useEffect(() => {
    ;(async function() {
      const newGameId = games[games.length - 1]
      setGameId(newGameId)
      const newBookId = await getMyBookId(roomId, newGameId, uid)
      setBookId(newBookId)
    })()
  }, [roomId, games, uid])

  const {setPage} = usePage()
  const reducer = function(prevState, action) {
    switch (action.type) {
      case 'submit':
        return {
          ...prevState,
          isSubmitted: true
        }
      case 'ready':
        return {
          ...prevState,
          isReady: true
        }
      case 'showCount':
        return {
          ...prevState,
          showCount: true
        }
      case 'setCount':
        return {
          ...prevState,
          count: action.count
        }
      default:
    }

  }
  const [status, dispatcStatus] = useReducer(reducer, {isSubmitted: false, isReady: false, showCount: false, count: 3})
  function callback(gameData, unsubscribe) {
    if(status.isReady) {
      return
    }
    if( gameData.entitled_book_num < playerNum ) {
      return
    } else {
      unsubscribe()
      dispatcStatus({type: 'ready'})
      setTimeout(()=>{
        dispatcStatus({type: 'showCount'})
        let count = 3
        const timer = setInterval(() => {
          count -= 1
          if(count < 1) {
            clearInterval(timer)
            setPage('playing')
          } else {
            dispatcStatus({type: 'setCount', count})
          }
        }, 1000)
      },1000)
    }
  }
  async function onSubmit() {
    if(!title) {
      alert('タイトルを入力してください');
      return;
    }
    if(title.length > 30) {
      alert('タイトルは最大で30文字までです');
      return;
    }
    await submitTitle(roomId, gameId, bookId, title)
    dispatcStatus({type: 'submit'})
    watchGameData(roomId, gameId, callback)
    updatePlayerReady(roomId, gameId, uid, {'type': 'setTitle'})
  }

  // form control
  const [title, setTitle] = useState('')
  function handleChange(e) {
    setTitle(e.target.value)
  }

  const inputNum = 4
  const [nounArr, setNounArr] = useState(Array(inputNum).fill(''))
  const [adjectiveArr, setAdjectiveArr] = useState(Array(inputNum).fill(''))
  function calcClickFunc(targetArr, targetIndex, setFunc) {
    return function(e) {
      const newArr = [...targetArr]
      newArr.splice(targetIndex,1,e.target.value)
      setFunc( newArr )
    }
  }

  // Todo: 名詞パートと形容詞パートで同じ処理を書いているので1つにまとめる
  const [randomWords, setRandomWords] = useState({dataA: [], dataB: []})
  async function randomizeNoun() {
    let randomNounArr
    if(randomWords.dataA && randomWords.dataA.length > 0) {
      randomNounArr = randomWords.dataA
    } else {
      const randomWords = await getWords()
      setRandomWords(randomWords)
      randomNounArr = randomWords.dataA
    }
    let newArr = [...nounArr].fill()
    const array  = [...randomNounArr]
    newArr = newArr.map(() => {
      return array.splice(Math.floor(array.length * Math.random()), 1)
    })
    setNounArr( newArr )
  }
  
  async function randomizeAdjective() {
    let randomAdjectiveArr
    if(randomWords.dataB && randomWords.dataB.length > 0) {
      randomAdjectiveArr = randomWords.dataB
    } else {
      const randomWords = await getWords()
      setRandomWords(randomWords)
      randomAdjectiveArr = randomWords.dataB
    }
    let newArr = [...adjectiveArr].fill()
    const array  = [...randomAdjectiveArr]
    newArr = newArr.map(() => {
      return array.splice(Math.floor(array.length * Math.random()), 1)
    })
    setAdjectiveArr( newArr )
  }

  function setRandomTitle() {
    const index1 = Math.floor(4 * Math.random())
    const index2 = Math.floor(4 * Math.random())
    setTitle( adjectiveArr[index1] + nounArr[index2] )
  }

  return (
    <div className="set-title">
      {
        !status.isSubmitted
          ? (
          <>
            <h2 className="set-title__title">小説の題名を決める</h2>
            <div className="set-title__content">
              <div className="set-title__block">
                <p className="set-title__heading"><span className="set-title__heading-num">1</span>思いつく名詞を4つ書いてみよう</p>
                <ul className="set-title__input-list">
                  {nounArr.map( (noun, i) => {
                    return  <li key={i}><input className="set-title__small-input" type="text"  value={noun} onChange={calcClickFunc(nounArr, i, setNounArr)}  placeholder={i===0 ? '例 : りんご': ''} /></li>
                  })}
                </ul>
                <div className="c-btn-wrapper"><button className="c-btn c-btn--color" onClick={randomizeNoun}>おまかせ</button></div>
              </div>

              <div className="set-title__block">
                <p className="set-title__heading"><span className="set-title__heading-num">2</span>名詞を１つ選び、その名詞から思いつくことを書いてみよう</p>
                <ul className="set-title__input-list">
                  {adjectiveArr.map( (adjective, i) => {
                    return  <li key={i}><input className="set-title__small-input" type="text" value={adjective} onChange={calcClickFunc(adjectiveArr, i, setAdjectiveArr)} placeholder={i===0 ? '例 : 赤くて丸い': ''} /></li>
                  })}
                </ul>
                <p className="c-btn-wrapper"><button className="c-btn c-btn--color" onClick={randomizeAdjective}>おまかせ</button></p>
              </div>

              <div className="set-title__block">
                <p className="set-title__heading"><span className="set-title__heading-num">3</span>②と①を組み合わせて不思議な題名をつくろう</p>
                <input className='set-title__input' type="text" placeholder="タイトル" value={title} onChange={handleChange} />
                <p className="c-btn-wrapper"><input className="c-btn c-btn--color" type="button" value="言葉を組み合わせる" onClick={setRandomTitle} /></p>
              </div>

            </div>

            {/* <div>オプション（ジャンルの設定をする）</div> */}
            
            <div className="set-title__bottom">
              <p className="c-btn-wrapper"><input className="c-btn" type="button" value=" 決定！" onClick={onSubmit} disabled={!title} /></p>
            </div>
          </>
          ) : (
            <div className="set-title-waiting">
              <div className="set-title-waiting__icon"><img src="" alt="" /></div>
              {
                !status.isReady
                  ? <p className="set-title-waiting__text">みんなが題名を決定するのを待っています</p>
                  : <p className="set-title-waiting__text">みんなの題名が決まりました！</p>
              }
              
              {/* <div className="set-title-waiting__advice">
                <p className="set-title-waiting__advice-title">ワンポイントアドバイス</p>
                <p className="set-title-waiting__advice-text">あえて文章の途中で回してみよう！</p>
              </div> */}
              {
                status.showCount &&
                  <div className="set-title-waiting__count"><span className="set-title-waiting__count-num">{status.count}</span></div>
              }
            </div>
          )
      }
    </div>
  )
}
