import {useState, useEffect} from 'react'
import { getAllBooks, getGameData } from '../firebase'
import imgBook from '../images/book.png'
import imgBookOpen from '../images/book-open.png'

export default function Main({room, uid}) {
  const roomId = room.id

  const [bookList, setBookList] = useState([])
  const [book, setBook] = useState({})

  useEffect(()=>{
    ;(async () => {
      // 最後に追加された gameId を取得
      const games = room.games
      const gameId = games[games.length - 1]
      
      // gameId から関連するブックの情報を取得
      const gameData = await getGameData(roomId, gameId)
      const playerOrder = gameData.order

      const bookIdArr = gameData.documents
      const myBookId = bookIdArr[playerOrder.indexOf(uid)]
      const books = await getAllBooks(bookIdArr)
      setBookList(books)
      setBook(books[myBookId])
    })()
  },[roomId, room.games, uid])

  const bookContents = (() => {
    if(Object.keys(book).length > 0) {
      return book.contents.map((contentItem, i) => {
        return (
          <div className="review__content-item" key={i}>
            <p className="review__content-text">
              {contentItem.content}
            </p>
            <p className="review__content-author">{contentItem.name}</p>
          </div>
        )
      })
    } else {
      return null
    }
  })()


  return (
    <div className="review">
      <h2 className="review__title">完成作品</h2>
      <ul className="review__book-list">
        {Object.keys(bookList).map((docId) => {
          const isOpen = book.id === docId
          return (
            <li className={'review__book-item' + (isOpen ? ' review__book-item--open' : '')} key={docId}>
              <button className="review__book-link" onClick={() => {setBook(bookList[docId])}}>
                <span className="review__book-icon"><img src={ isOpen ? imgBookOpen : imgBook} alt="" /></span><br />
                <span className="review__book-owner">{bookList[docId].ownerName}</span><br />
                <span className="review__book-title">『{bookList[docId].title}』</span>
              </button>
            </li>
          )}
        )}
      </ul>
      { Object.keys(book).length > 0 && 
        <div className="review__content">
          <p className="review__content-title">『{book.title}』</p>
          {bookContents}
        </div>
      }
      <div className="review__info">
        <p>本サービスは試用版です。バグや不具合を発見されたり、機能面などに関するリクエストがございましたら、「#じゃれ本オンラインリクエスト」をつけてTwitterでつぶやいていただけますと幸いです。<br />なお、今後検討している機能は「<a href="https://jarebon.com/releasenote/" target="_blank">リリースノート</a>」に記載しています。</p>{/* eslint-disable-line react/jsx-no-target-blank */}
        <p>「じゃれ本」は「<a href="https://jarebon.official.ec/" target="_blank">オンラインストア</a>」からお求めいただけます。<br />リアルな場で集まってプレイするのも醍醐味のひとつです。ぜひ遊んでみてください！</p>{/* eslint-disable-line react/jsx-no-target-blank */}
      </div>
    </div>
  )
}
