import { useState, useEffect } from 'react'
import imgPlayer from '../images/player.png'
import { usePage } from '../context/page'
import { getRoomData, submitNewGame, waitForPlay, watchGameData } from '../firebase'

export default function WaitForPlay({uid, room, setRoomData}) {

  const isOwner = uid === room.owner

  const {setPage} = usePage()
  useEffect(() => {
    ;(async () => {
      const newRoomStatus = await waitForPlay(room.id, setRoomData);
      if(newRoomStatus === 'playing') {
        const roomData = await getRoomData(room.id);
        setRoomData(roomData); //監視に失敗していた時の保険
        setPage('entitle')
      }
    })()
  }, [room.id, setRoomData, setPage])

  const [game, setGame] = useState({})
  const gameId = room.games[room.games.length - 1]
  useEffect(() => {
    return watchGameData(room.id, gameId, (data)=>{setGame(data)})
  }, [room, gameId])

  async function startGame() {
    try {
      await submitNewGame(room, gameId, game);
    } catch(err) {
      console.error(err);
      alert('ゲームを開始できません');
    }
    return;
  }

  return (
    <div className="waiting-room">
      <p className="waiting-room__room-name">{room.name}</p>
      <p className="waiting-room__room-id">ID: {room.id}</p>
      <div className="player-list">
        { room.playerNum > 0 &&
          <ul className="player-list__list">
            {
              Object.entries(room.players).map((ele, i) => {
                const [uid, userInfo] = ele;
                return (
                  <li className="player-list__item" key={uid}>
                    <span className="player-list__num">{1 + i}</span>
                    <span className="player-list__icon"><img src={imgPlayer} alt="" /></span>
                    <span className="player-list__name">{userInfo.name}</span>
                  </li>
                )
              })
            }
          </ul>
        }
      </div>
      <div className="waiting-room__footer">
        {isOwner 
          ? (
            <div className="c-btn-wrapper">
              <div className="waiting-room__status">
                <p className="waiting-room__status-text">現在</p>
                <p className="waiting-room__status-num">{room.playerNum} 人</p>
              </div>
              <input className="c-btn" type="button" value="ゲームスタート" onClick={startGame}/>
            </div>
          )
          : <div className="waiting-room__waiting-text"><p className="waiting-room__waiting-text-inner">ホストがゲームを開始するのを待っています</p></div>
        }
      </div>
    </div>
  )
}
