import { useState } from 'react'
import Header from '../components/Header.js';

import {LoadingProvider} from '../context/loading.js';
import {usePage} from '../context/page.js';

import CreateRoom from '../page/CreateRoom.js';
import JoinRoom from '../page/JoinRoom.js';
import WaitForPlay from '../page/WaitForPlay.js';
import SetTitle from '../page/SetTitle.js';
import Main from '../page/Main.js';
import Review from '../page/Review.js';
import Initial from '../page/Initial.js';


const uid = false || Math.random().toString(32).substring(2);

export default function Contetns() {
  const { page } = usePage()
  const [roomData, setRoomData] = useState({})
  const room = {
    ...roomData,
    playerNum: Object.keys(roomData.players || {}).length
  }

  return (
    <div className="contents">
      <Header isLink={page === 'review'} />

      <LoadingProvider>
      {/* <div className="contents"> */}
        { page === 'initial' && <Initial />}
        { page === 'createRoom' && <CreateRoom {...{uid, setRoomData}} />}
        { page === 'joinRoom' && <JoinRoom {...{uid, setRoomData}} />}
        { page === 'waiting' && <WaitForPlay {...{uid, room, setRoomData}} />}
        { page === 'entitle' && <SetTitle {...{room, uid}} />}
        { page === 'playing' && <Main {...{room, uid}} />}
        { page === 'review'  && <Review {...{room, uid}} />}
      {/* </div> */}
      {
        // loading.isLoading &&
        // <div className="loading">

        // </div>
      }
      </LoadingProvider>
    </div>
  )
}