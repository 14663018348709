import './App.scss';
import Contents from './components/Contents.js';
import { PageProvider } from './context/page';

export default function App() {
  return (
    <div className="App">
      <PageProvider>
        <Contents />
      </PageProvider>
    </div>
  )
}

