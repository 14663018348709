import {useState, useEffect, useCallback} from 'react'
import { usePage } from '../context/page'
import { calcTargetBookId, getGameData, updatePlayerReady, watchGameData } from '../firebase'
import Book from '../components/Book'

export default function Main({room, uid}) {
  
  // 最後に追加された gameId を取得
  const games = room.games
  const gameId = games[games.length - 1]
  const [game, setGame] = useState(getGameData(room.id, gameId))
  
  const {setPage} = usePage()
  // todo: Book.js に設定されている totalPageNum と連携させる
  const callBack = useCallback(
    (updatedGameData) => {
      if(updatedGameData.currentRound >= updatedGameData.config.pageNum) {
        setPage('review')
      } else {
        setGame(updatedGameData)
      }
    },
    [setGame, setPage]
  )
  
  useEffect(()=>{
    if(!gameId) {
      return
    }
    return watchGameData(room.id, gameId, callBack)
  },[room.id, gameId, callBack])

  function onSubmit() {
    updatePlayerReady(room.id, gameId, uid)
  }

  const playerNumIsReady = game.player_is_ready && Object.entries(game.player_is_ready).filter((ele) => ele[1]).length
  const bookId = Object.keys(game).length ? calcTargetBookId(game, uid) : null
  const pageNum = game.config?.pageNum
  return (
    <div className="new-page">

      { bookId && <Book {...{game, bookId, uid, pageNum, onSubmit, room}} /> }     

      <div className="num-player-ready">
        <p className="num-player-ready__text">
          {/* {Object.entries(game.player_is_ready).map(([id, isReady]) => {return })} */}
          書き終えた人　{playerNumIsReady} 人 / {room.playerNum} 人
        </p>
      </div>
    </div>
  )
}
