function ProgressRing({radius, stroke, progress, num}) {
  const normalizedRadius = radius - stroke / 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - progress / 100 * circumference;
  const barColor = (()=>{
    if (num === 0) {
      return '#FF5B15'
    } else if (num <= 20) {
      return 'orange'
    } else {
      return 'currentColor'
    }
  })()
  return (
    <div className='progress-ring'>
      <div className="progress-ring__inner">
        <svg
          height={radius * 2}
          width={radius * 2}
        >
          <circle
            fill="none"
            strokeWidth={ stroke }
            stroke="#C8C8C8"
            r={ normalizedRadius }
            cx={ radius }
            cy={ radius }
          />
          <circle
            className='progress-ring__bar'
            stroke={ barColor }
            fill="transparent"
            strokeWidth={ stroke }
            strokeDasharray={ circumference + ' ' + circumference }
            style={ { strokeDashoffset } }
            r={ normalizedRadius }
            cx={ radius }
            cy={ radius }
          />
        </svg>
        { typeof num === 'number' && num <= 20 && <div className={'progress-ring__number' + (num === 0 ? ' progress-ring__number--limit':'')}>{num}</div> }
      </div>
    </div>
  )
}

export default ProgressRing